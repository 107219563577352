import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';

const Free = () => {
    const applications = [
        {
            name: 'Searx',
            category: 'Moteur de recherche',
            logo: 'https://i.imgur.com/o8YNYEg.png',
            description: 'Alternative open-source à Google',
            website: 'https://search.ethibox.fr',
        },
        {
            name: 'Bibliogram',
            category: 'Photo',
            logo: 'https://i.imgur.com/4Rim0ur.png',
            description: 'Une application front-end pour Instagram',
            website: 'https://bibliogram.ethibox.fr',
        },
        {
            name: 'Draw.io',
            category: 'Diagrammes',
            logo: 'https://i.imgur.com/7Sr9KEl.png',
            description: 'Une app de diagrammes open source',
            website: 'https://drawio.ethibox.fr',
        },
        {
            name: 'Lufi',
            category: 'Stockage',
            logo: 'https://i.imgur.com/wxE3KuZ.png',
            description: 'Espace de stockage temporaire',
            website: 'https://lufi.ethibox.fr',
        },
        {
            name: 'Mastodon',
            category: 'Réseau social',
            logo: 'https://i.imgur.com/ZEZFTrL.png',
            description: 'Alternative open-source à Twitter',
            website: 'https://mastodon.ethibox.fr',
        },
        {
            name: 'Peertube',
            category: 'Video',
            logo: 'https://i.imgur.com/sskbhVa.png',
            description: 'Alternative open-source à YouTube',
            website: 'https://peertube.ethibox.fr',
        },
        {
            name: 'Pixelfed',
            category: 'Photo',
            logo: 'https://i.imgur.com/tzMI4gY.png',
            description: 'Alternative open-source à Instagram',
            website: 'https://pixelfed.ethibox.fr',
        },
        {
            name: 'Jitsi',
            category: 'Tchat Video',
            logo: 'https://i.imgur.com/LRTsJfB.png',
            description: 'Alternative open-source à Skype',
            website: 'https://jitsi.ethibox.fr',
        },
        {
            name: 'Pytition',
            category: 'Pétitions',
            logo: 'https://i.imgur.com/7bcUMxB.png',
            description: 'Un outil libre pour pétitions',
            website: 'https://pytition.ethibox.fr',
        },
        {
            name: 'Scrumblr',
            category: 'Kanban',
            logo: 'https://i.imgur.com/Er1jV8P.png',
            description: 'Espace collaboratif de notes',
            website: 'https://scrumblr.ethibox.fr',
        },
        {
            name: 'Mobilizon',
            category: 'Évènements',
            logo: 'https://i.imgur.com/a2OfEmb.png',
            description: 'Alternative open-source à Meetup',
            website: 'https://mobilizon.ethibox.fr',
        },
        {
            name: 'Writefreely',
            category: 'Écrire',
            logo: 'https://i.imgur.com/QxAHQ10.png',
            description: 'Platforme de blogging open-source',
            website: 'https://writefreely.ethibox.fr',
        },
        {
            name: 'Nitter',
            category: 'Réseau social',
            logo: 'https://i.imgur.com/73PYui9.png',
            description: 'Une application front-end pour Twitter',
            website: 'https://nitter.ethibox.fr',
        },
        {
            name: 'Framadate',
            category: 'Sondages',
            logo: 'https://i.imgur.com/QQ0svc1.png',
            description: 'Application open-source de sondages',
            website: 'https://framadate.ethibox.fr',
        },
    ];

    return (
        <Layout>
            <SEO title="Applications gratuites" />
            <section className="py-10">
                <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
                    <h3 className="text-center mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                        Applications gratuites
                    </h3>

                    <p className="text-center mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto">
                        Naviguez sur le web de manière éthique
                    </p>

                    <div className="grid gap-3 sm:grid-cols-2 sm:gap-3 lg:grid-cols-3 lg:gap-3 xl:grid-cols-4 xl:gap-4 mt-10">
                        { applications.map(({ name, category, logo, website, description }) => {
                            return (
                                <div className="bg-white overflow-hidden shadow rounded-lg" key={name}>
                                    <div className="px-4 py-5 sm:p-6 lg:p-4">
                                        <div className="flex justify-between">
                                            <div>
                                                <h1 className="font-bold">{name}</h1>
                                                <h2 className="capitalize text-sm">{category}</h2>
                                            </div>
                                            <img src={logo} alt="logo" className="w-14 h-14" />
                                        </div>

                                        <p className="text-sm text-gray-700 mt-2">{description}</p>

                                        <div className="mt-8 border-t border-gray-200 pt-5">
                                            <a
                                                href={website}
                                                target="_blank"
                                                type="button"
                                                className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                                            >
                                                Accéder
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            );
                        }) }
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default Free;
